import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'gatsby';
import typer from 'typer-js';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Accordion from '../components/Accordion';
import FeatureSlider from '../components/FeatureSlider';
import Intersection from '../components/Intersection';
import Tasks from '../components/Tasks';
import '../styles/homepage.scss';
import Popup from '../components/Popup';

const IndexPage = () => {
  const [loaded, setLoaded] = useState(false);
  const typerBlock = useRef();
  const [modalVisible, setModalVisible] = useState('');

  const togglePopup = useCallback(
    (value) => setModalVisible((state) => (state === value ? '' : value)),
    [],
  );
  const closePopup = useCallback(() => setModalVisible(0), []);

  useEffect(() => {
    modalVisible
      ? document.body.classList.add('overflow')
      : document.body.classList.remove('overflow');
  }, [modalVisible]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const runTyper = useCallback(() => {
    const words = ['remote work', 'task management', 'virtual meetings'];

    const instance = typer(typerBlock.current).cursor().line();

    words.forEach((word) => {
      instance

        .continue(word, 100)
        .pause(1000) // Pause for 1 second after each word is typed.
        .back('all', 5); // Erase that word, leaving a blank slate for the next one.
    });

    return instance.repeat(Infinity, true);
  }, []);

  useEffect(() => {
    runTyper();
  }, [runTyper]);

  return (
    <Layout headerHero togglePopup={togglePopup}>
      <SEO title="Simple To-Do | Virtual Office | No Micro-Management" />
      <main
        style={{
          transition: 'opacity 0.1s',
          transitionDelay: '0.5s',
          opacity: loaded ? 1 : 0,
        }}>
        <Popup modalVisible={modalVisible === 'spaces'} closePopup={closePopup}>
          <div className="space-video">
            <video
              autoPlay
              loop
              muted
              playsInline
              src="/video/spaces.mp4"
              style={{ width: '500px' }}></video>
          </div>
          <h4>Spaces by Brutask</h4>
          <p>
            Spaces by Brutask is created to replicate your virtual office.
            Navigate across floors, join a room &amp; discuss with your team via
            audio &amp; video. You can also share your screen with all othe
            participants inside the room. What’s better - you can share the room
            link with external members outside your team, and they can join
            without any login!
          </p>
          <a className="button" href="http://app.brutask.com/">
            Get started
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
              <path
                d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            className="button button-unstyled"
            href="https://www.loom.com/share/c0ad1d9ddb6f48fe9e1040c7f6550ca3"
            target="_blank"
            rel="noopener noreferrer">
            Watch Demo
          </a>
        </Popup>
        <Popup
          modalVisible={modalVisible === 'pricing'}
          closePopup={closePopup}
          className="popup-center">
          <h3>
            Pay as you <span className="yellow">grow</span>
          </h3>
          <p>
            You will be charges <span className="semi-bold">$15/month</span> for
            the plan that will include: 10 members, 3 floors and unlimited guest
            access. <span className="semi-bold">$2</span> would be charged for
            every additional member
          </p>
          <a className="button" href="http://app.brutask.com/">
            Get started
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
              <path
                d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                fill="white"
              />
            </svg>
          </a>
        </Popup>
        <div className="index-hero white-bg">
          <div className="container text-center">
            <div className="logo">
              <Link to="/">
                <img src="/img/brutask-logo.svg" alt="" />
              </Link>
            </div>
            <h1>
              Making
              <br className="visible-tablet" /> <span ref={typerBlock} />
              <br /> more human!
            </h1>
            <p>
              Brutask is the simplest collaboration tool for remote teams
              looking to boost their efficiency by increasing individual
              productivity.
            </p>
            <a className="button" href="http://app.brutask.com/">
              Get started
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                <path
                  d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
        <Intersection />
        <Tasks />
        <div className="index-habit white-bg">
          <div className="container">
            <h2 className="heading">
              Brutask is less like a tool and more like your&nbsp;
              <span className="green">virtual office!</span>
            </h2>
            <div className="habit-block relative">
              <div className="decoration">
                <img src="/img/spiral.svg" alt="" />
              </div>
              <div className="row">
                <div className="col-6 col">
                  <h3 className="yellow">
                    We’ve <br className="hidden-mobile" />
                    cracked the code of
                    <span className="white"> remote working</span> for teams
                  </h3>
                  <p className="white weight-500" style={{ maxWidth: 377 }}>
                    The experience is almost like working in your office with
                    your colleagues.
                  </p>
                </div>
                <div className="col-6 col align-self-end">
                  <div className="habit-box">
                    <img src="/img/tick-green.svg" alt="" />
                    <p className="inline-block">
                      Stay connected all-time, full-time!
                    </p>
                  </div>
                  <div className="habit-box">
                    <img src="/img/tick-green.svg" alt="" />
                    <p className="inline-block">Make meetings more exciting!</p>
                  </div>
                  <div className="habit-box">
                    <img src="/img/tick-green.svg" alt="" />
                    <p className="inline-block">
                      Hang out with the team during break times!&nbsp;🤩
                    </p>
                  </div>
                  <a
                    type="button"
                    onClick={() => togglePopup('spaces')}
                    className="button button-yellow">
                    Check Spaces
                    <div className="spaces-icon spaces-icon-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="index-features bg-lightgrey">
          <div className="container text-center">
            <h2 className="heading">
              Brutask is <span className="green">different</span>
            </h2>
            <p className="content margin-auto" style={{ maxWidth: 711 }}>
              Other task managers are all about project progress, Brutask
              focuses on increasing individual productivity, that ultimately
              improves team efficiency.
            </p>
            <FeatureSlider togglePopup={togglePopup} />
            <a href="http://app.brutask.com/" className="button button-green">
              Get started
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="index-add white-bg">
          <div className="container">
            <h2 className="heading">
              Add members, assign managers and supervise
            </h2>
            <div className="add-members-video">
              <video
                autoPlay
                loop
                muted
                playsInline
                src="/video/add-members.mp4"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
        <div className="index-testimonial bg-lightgrey">
          <div className="container">
            <div className="pay-block">
              <div className="decoration">
                <img
                  className="hidden-mobile"
                  src="img/decoration-1.svg"
                  alt=""
                />
                <img
                  className="visible-mobile"
                  src="img/decoration-1-mob.svg"
                  alt=""
                />
              </div>
              <div className="decoration decoration-1">
                <img src="img/decoration-2.svg" alt="" />
              </div>
              <h3>Pay as you grow</h3>
              <p>
                Wish we could give the product for free, but good things come
                with a price tag. Our plan starts from $15 for 10 users and $2
                per additional user.
              </p>
              <button
                type="button"
                onClick={() => togglePopup('pricing')}
                className="button button-white">
                See plans
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                    fill="#000000"
                  />
                </svg>
              </button>
            </div>
            <div className="testimonial">
              <div className="row">
                <div className="col-5 col">
                  <div className="relative">
                    <img
                      src="/img/testimonial-1.jpg"
                      alt="siddhita"
                      className="author-image"
                    />
                    <img src="/img/star-blue-8.svg" alt="" className="star" />
                  </div>
                </div>
                <div className="col-7 col">
                  <div className="testimonial-main">
                    <h4 className="testimonial-head">A tip to use Brutask</h4>
                    <span className="author">
                      Siddhita Upare, Director Brutask
                    </span>
                    <h4 className="testimonial-content">
                      Onboard your entire team by sending the invite link or
                      adding their email IDs, &amp; ask them to add their tasks
                      daily before beginning the day. The team can now connect
                      over Spaces &amp; call out their tasks. At the end of the
                      day, re-connect on Spaces to check the progress &amp; add
                      tasks for the next day.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="index-faq white-bg">
          <div className="container">
            <div className="text-center">
              <img src="/img/binocular.svg" alt="" className="binocular" />
            </div>
            <h2 className="heading text-center">FAQs</h2>
            <Accordion />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
