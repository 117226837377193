import React from 'react';
import './accordion.scss';
import { faq } from '../../utils/contentProviders';
import Collapse from 'rc-collapse';
var Panel = Collapse.Panel;
require('rc-collapse/assets/index.css');

function Accordion() {
  return (
    <Collapse className='accordion' accordion={true}>
      {faq.map(({q, a},id) => (
        <Panel key={id}
          showArrow={false}
          header={q}
          headerClass='accordion-header'
        >
          {a}
        </Panel>
      ))}
    </Collapse>
  );
}

export default Accordion;
