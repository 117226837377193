import React, { useState, useCallback, useEffect } from 'react';
import './tasks.scss';

function Accordion() {
  const [task, setTask] = useState(false);
  const [autoToggle, setAutoToggle] = useState(true);

  const taskSwitch = useCallback(() => {
    setTask((state) => !state);
  }, []);

  const taskClickHandler = useCallback(() => {
    taskSwitch();
    setAutoToggle(false);
  }, []);

  useEffect(() => {
    let timer;
    if (autoToggle) {
      timer = setTimeout(taskSwitch, task ? 8000 : 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [task, autoToggle]);

  return (
    <div className="index-tasks bg-lightgrey">
      <div className="container">
        <div className="text-center">
          <label className="switch">
            <input
              type="checkbox"
              checked={!task}
              name="switch"
              value="switch"
              onChange={taskClickHandler}
            />
            <span className="slider"></span>
            <span className="label label-task">Tasks</span>
            <span className="label label-spaces">Spaces</span>
          </label>
        </div>
        <div className="tasks-image text-center">
          {task ? (
            <div className="task-video">
              <video
                autoPlay
                loop
                muted
                playsInline
                src="/video/task.mp4"
                style={{ maxWidth: 351 }}
              ></video>
            </div>
          ) : (
            <div className="space-video">
              <video
                autoPlay
                loop
                muted
                playsInline
                src="/video/spaces.mp4"
              ></video>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
