import React, { useEffect } from 'react';
import './feature-slider.scss';
import { features } from '../../utils/contentProviders';

function FeatureSlider({ togglePopup }) {
  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const FeatureSliderContainer = document.querySelector('.feature-slider');

      if (FeatureSliderContainer) {
        const FeatureSlider = tns({
          container: FeatureSliderContainer,
          nav: false,
          mouseDrag: true,
          items: 4,
          loop: false,
          controlsText: [
            `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" transform="rotate(-180 24 24)" fill="#050505"/>
            <path d="M28.62 14.99C28.13 14.5 27.34 14.5 26.85 14.9899L18.54 23.3C18.15 23.69 18.15 24.32 18.54 24.71L26.85 33.02C27.34 33.51 28.13 33.51 28.62 33.02C29.11 32.53 29.11 31.74 28.62 31.25L21.38 24L28.63 16.75C29.11 16.27 29.11 15.4699 28.62 14.99Z" fill="#8D9498"/>
            </svg>
            `,
            `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#050505"/>
            <path d="M19.38 33.01C19.87 33.5 20.66 33.5 21.15 33.01L29.46 24.7C29.85 24.31 29.85 23.68 29.46 23.29L21.15 14.98C20.66 14.49 19.87 14.49 19.38 14.98C18.89 15.47 18.89 16.26 19.38 16.75L26.62 24L19.37 31.25C18.89 31.73 18.89 32.5301 19.38 33.01Z" fill="#8D9498"/>
            </svg>
            `,
          ],
          responsive: {
            0: {
              disable: true,
            },
            768: {
              disable: false,
            },
          },
        });
      }
    });
  }, []);

  return (
    <div className="feature-slider">
      {features.map(({ img, heading, content = '', cta = '' }, id) => (
        <div key={id}>
          <div className="feature-block">
            <img src={img} alt="" />
            <h4>{heading}</h4>
            {content ? <p className="feature-content">{content}</p> : ''}
            {cta ? (
              <>
                <div className="blur hidden-mobile">
                  <button
                    type="button"
                    onClick={() => togglePopup('spaces')}
                    className="button button-green cta"
                  >
                    {cta.name ? cta.name : ''}
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
                {cta.name ? (
                  <button
                    type="button"
                    onClick={() => togglePopup('spaces')}
                    className="cta-mob visible-mobile"
                  >
                    {cta.name}
                  </button>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeatureSlider;
