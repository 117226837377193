export const features = [
  {
    img: '/img/tasks.svg',
    heading: 'Add Tasks',
    content: `Add your tasks for the day & update them. Check them once they're done.`,
  },
  {
    img: '/img/time-box.svg',
    heading: 'Time Boxing',
    content: `Allocate time for each task for better time management & organization.`,
  },
  {
    img: '/img/prioritization.svg',
    heading: 'Prioritisation',
    content: `Prioritise the most important tasks of the day to the top, so you do what needs to be done first.`,
  },
  {
    img: '/img/video-calls.svg',
    heading: 'Video Calls',
    content: `Hop on video calls, present your screen, invite external guests & collaborate with your entire team.`,
  },
  {
    img: '/img/calendar.svg',
    heading: 'Connect Calendar',
    content: `Integrate with Google Calendar, join and respond to meetings directly on Brutask.`,
  },
  {
    img: '/img/virtual-office.svg',
    heading: 'Virtual office',
    content:
      'Navigate across floors, join a room & have fun-filled collaborations with your team.',
    cta: {
      name: 'Learn more',
      url: 'https://www.dropbox.com/scl/fi/k8qmlj5m997mzcxiu12bj/Spaces-by-Brutask.paper?dl=0&rlkey=jl379k5yhh2fwa1oe55sbbpco',
    },
  },
];

export const faq = [
  {
    q: 'How is Brutask useful for me?',
    a: 'The human brain is capable of remembering only 4-5 tasks at once. This is why we introduced Brutask. Bring your day’s tasks & calendar meetings to one place & reduce your mental load. Tick the tasks or meetings which are done, and experience better productivity levels!',
  },
  {
    q: 'How Brutask is different from other tools?',
    a: 'Other task managers are all about project progress, Brutask focuses on increasing individual productivity, that ultimately improves team efficiency.',
  },
  {
    q: 'How to get the most out of Brutask?',
    a: 'Onboard your entire team by sending the invite link or adding their email IDs, & ask them to add their daily tasks before beginning the day. Collaborate on Spaces and make your stand-up & wrap-up calls exciting. Connect Google Calendar to check all your meetings directly in Brutask, and attend or respond to them right away!',
  },
  {
    q: 'What are the benefits of integrating my Google Calendar with Brutask?',
    a: 'Integrate your Google Calendar with Brutask to see your entire day’s schedule in one place. Check which meetings are scheduled for today, or upcoming this week. Click on them to respond with a Yes/No/Maybe, or click on the Meet/Zoom link to directly join the call from Brutask itself.',
  },
  {
    q: 'How do I integrate my Google Calendar with Brutask?',
    a: 'To integrate with Google Calendar, please go to the ‘Manage’ section and click on ‘Integrations’. Toggle the Google Calendar integration to on, allow for permissions, and your Google Calendar will be successfully integrated into Brutask.',
  },
  {
    q: 'How is Spaces useful?',
    a: 'Spaces by Brutask allows you to collaborate with your team in a fun-filled way. It is created to replicate your virtual office. Navigate across floors, join a room & discuss with your team via audio & video. You can also share your screen with all other participants inside the room. What’s better - you can share the room link with external members outside your team, and they can join without any login! Lock the room in case you’d like to hold a closed meeting or a private conversation.',
  },
  {
    q: 'How do I access Spaces?',
    a: 'On your tasks screen, please click on the animated ‘revolving cube’ icon. Rename rooms & join them using your arrow keys. This way you can begin your stand-up, or collaborate in the most fun-filled way!',
  },
  {
    q: 'How do I share my room link with external guests or clients?',
    a: 'Simply hover on the room where you’d like to collaborate with your guest(s). Clicking on the ‘link-shaped’ icon will copy the joining link to your clipboard, post which you can keep this link in your Google Calendar or share it with your guest(s). Your guest(s) will simply have to submit their name along with their email address to join the room.',
  },
];

export function getErrorMessage(type, inputName) {
  const genericMessage = 'This input is invalid.';
  let messages;

  switch (type) {
    case 'required':
      return 'This input is required.';

    case 'error': {
      messages = {
        email: 'This is an invalid email.',
        companyEmail: 'Please enter your company email address.',
        phoneNumber: 'This is an invalid mobile number.',
        otp: 'This is an invalid OTP.',
        date: 'This is an invalid date.',
        password: 'Password must contain 8 characters.',
        // password:
        // 'Password must contain 8 characters including at least 1 uppercase, 1 lowercase, 1 digit and 1 special character.',
        confirmPassword: 'Password and Confirm Password must match.',
      };

      return inputName ? messages[inputName] : genericMessage;
    }

    default:
      return genericMessage;
  }
}
